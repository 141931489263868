.label-icon {
  // display: flex;
  // flex-direction: row;
  // align-items: center;
  // justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  white-space: nowrap;

  @media screen and (max-width: 560px) {
    justify-content: flex-start;
  }

  & > span {
    text-align: left;
    text-transform: uppercase;
    display: block;
    @include applyFontSchema($bodySmall, $mulishFamily, $regular);

    & > b {
      text-align: left;
      text-transform: uppercase;
      @include applyFontSchema($bodySmall, $mulishFamily, $bold);
    }
  }

  & > img {
    margin: 0 $s 0 0;
  }
}
