.property {
  background-color: $whiteLight;
  display: flex;
  border-radius: $m;
  justify-content: space-between;
  flex-direction: row;
  max-width: 80%;
  margin: 0 auto $xl auto;
  // max-height: 432px;
  max-height: 380px;

  @media screen and (max-width: 1140px) {
    max-height: unset;
  }

  &:last-child {
    margin: 0 auto 0 auto;
  }

  &.exclusive,
  &.new {
    border-top: $xs solid $yellow;
  }
  &.sale {
    border-top: $xs solid $maline;
  }

  &.sold {
    border-top: $xs solid $maline;
  }

  &.secret {
    border-top: $xs solid #af7cf1;
  }

  & .exclusive,
  & .new {
    color: $yellow;
  }

  & .sale {
    color: $maline;
  }

  & .sold {
    color: $maline;
  }

  & .secret {
    color: #af7cf1;
  }

  &.even {
    flex-direction: row-reverse;

    @media screen and (max-width: 1140px) {
      flex-direction: column-reverse;
    }

    &.animation {
      animation: fadeInToLeft 0.8s ease-in-out;
    }
  }

  &.odd {
    @media screen and (max-width: 1140px) {
      flex-direction: column-reverse;
    }

    &.animation {
      animation: fadeInToRight 0.8s ease-in-out;
    }
  }

  &__container-details {
    padding: 6px $xxl;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    @media screen and (max-width: 700px) {
      padding: 8px 16px 20px;
    }
  }

  &__details {
    width: 100%;
    // padding: $xxl;
    text-align: center;
    display: flex;
    flex-direction: column;
    color: black;
    cursor: pointer;

    @media screen and (max-width: 700px) {
      padding: 16px;
    }

    & > a > span {
      word-break: break-all;
      padding: 0 0 20px 0;
      @include applyFontSchema($bodyLarge, $mulishFamily, $regular);
    }
  }

  &__pre-header {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 $m 0;

    @media screen and (max-width: 700px) {
      padding: 0 0 $xs 0;
    }

    @media screen and (max-width: 370px) {
      flex-direction: column;
    }
  }

  &__type {
    text-transform: uppercase;
  }

  &__type,
  &__ref {
    @include applyFontSchema($bodyLarge, $mulishFamily, $bold);
  }

  &__ref {
    color: $brownLight;
  }

  &__header {
    width: 100%;
    height: 100%;
    margin: 0 0 $m 0;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;

    text-align: left;

    @media screen and (max-width: 700px) {
      padding: 0 0 $s 0;
    }

    @media screen and (max-width: 640px) {
      flex-direction: column;
      align-items: center;
    }

    &-layout {
      min-width: fit-content;
      position: relative;
      z-index: 0;
    }
  }

  &__title {
    margin: 0 $m 0 0;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    max-height: calc(1 * 28px);
    min-height: calc(1 * 28px);
    @include applyFontSchema($titleLarge, $pfdFamily, $bold);
    font-size: 18px;

    @media screen and (max-width: 1140px) {
      overflow: unset;
      display: unset;
      -webkit-line-clamp: unset;
      line-clamp: unset;
      -webkit-box-orient: unset;
      max-height: unset;
      min-height: unset;
    }

    @media screen and (max-width: 640px) {
      margin: 0 0 $xs 0;
      text-align: center;
    }
  }

  &__price {
    position: relative;
    z-index: 0;
    @include applyFontSchema($headlineSmall, $mulishFamily, $bold);
    color: #bf9a6d;
  }

  &__discount {
    position: absolute;
    right: 0;
    top: -$sm;
    text-decoration: line-through;
    color: $brownLight;
    @include applyFontSchema($titleSmall, $mulishFamily, $bold);
  }

  &__description {
    text-align: left;
    color: $brown;
    margin: 0 0 $m 0;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    // line-clamp: 4;
    -webkit-box-orient: vertical;
    max-height: 90px;
    min-height: 90px;
    @include applyFontSchema($bodyLarge, $mulishFamily, $regular);

    @media screen and (max-width: 1450px) {
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
      max-height: calc(2 * $l);
      min-height: calc(2 * $l);
    }
  }

  &__info {
    width: 100%;
    height: 100%;
    display: flex;
    // display: grid;
    // grid-template-columns: repeat(4, 1fr);
    flex-wrap: wrap;
    gap: 12px;
    margin-bottom: 12px;

    // @media screen and (max-width: 1580px) {
    //   grid-template-columns: repeat(3, 1fr);
    // }
    // @media screen and (max-width: 1400px) {
    //   grid-template-columns: repeat(2, 1fr);
    // }

    // @media screen and (max-width: 1140px) {
    //   grid-template-columns: repeat(4, 1fr);
    // }
    // @media screen and (max-width: 880px) {
    //   grid-template-columns: repeat(3, 1fr);
    // }
    // @media screen and (max-width: 750px) {
    //   grid-template-columns: repeat(2, 1fr);
    // }
    // @media screen and (max-width: 510px) {
    //   grid-template-columns: repeat(1, 1fr);
    // }
    & > .label-icon {
      float: left;

      // margin: 0 $xl $sm 0;
    }
  }

  &__controllers {
    display: grid;
    grid-template-columns: repeat(2, auto);
    column-gap: $s;
    row-gap: $s;

    @media screen and (max-width: 580px) {
      grid-template-columns: repeat(1, auto);
      row-gap: $s;
    }
  }

  &__layout {
    width: 100%;
    max-width: 32%;
    position: relative;
    z-index: 4;

    @media screen and (max-width: 1800px) {
      max-width: 44%;
    }

    @media screen and (max-width: 1140px) {
      max-width: 100%;
    }

    & > img {
      height: 100%;
      width: 100%;
      object-fit: cover;

      &.even {
        border-radius: $sm 0 0 $sm;
      }

      &.odd {
        border-radius: 0 $sm $sm 0;
      }

      @media screen and (max-width: 1140px) {
        border-radius: $sm $sm 0 0 !important;
        object-fit: contain;
      }
    }
  }

  &__controller {
    position: absolute;
    height: 100%;
    width: $c;
    z-index: 5;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &.left {
      left: 0;
      top: 0;
      bottom: 0;
      background: linear-gradient(
        90deg,
        rgba(0, 0, 0, 0.442602) -5.92%,
        rgba(0, 0, 0, 0) 80.26%
      );
    }

    &.right {
      right: 0;
      top: 0;
      bottom: 0;
      background: linear-gradient(
        270deg,
        rgba(0, 0, 0, 0.442602) -5.92%,
        rgba(0, 0, 0, 0) 80.26%
      );
    }

    &.even {
      border-radius: $sm 0 0 $sm;
    }

    &.odd {
      border-radius: 0 $sm $sm 0;
    }

    @media screen and (max-width: 1140px) {
      border-radius: $sm $sm 0 0 !important;
    }
  }

  &__slider {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 4;

    & div {
      height: 100%;

      @media screen and (max-width: 1140px) {
        height: unset;
      }
    }

    & .slick-slide {
      max-width: 83vw;
      & div {
        & > img {
          width: 130% !important;
          display: inline-block !important;
          z-index: 1000 !important;
          object-fit: cover !important;

          @media screen and (max-width: 1440px) {
            width: 130% !important;
          }

          @media screen and (max-width: 1260px) {
            width: 135% !important;
          }

          @media screen and (max-width: 500px) {
            width: 150% !important;
          }

          @media screen and (max-width: 370px) {
            width: 175% !important;
          }
        }
      }
    }

    & .slick-center {
      & div {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }
    }

    & img {
      height: 100%;
      width: fit-content;

      @media screen and (max-width: 1140px) {
        border-radius: $sm $sm 0 0 !important;
        width: unset;
        height: unset;
      }
    }

    &.even {
      div,
      img {
        border-radius: $sm 0 0 $sm;

        @media screen and (max-width: 1140px) {
          border-radius: $sm $sm 0 0 !important;
        }
      }
    }

    &.odd {
      div,
      img {
        border-radius: 0 $sm $sm 0;

        @media screen and (max-width: 1140px) {
          border-radius: $sm $sm 0 0 !important;
        }
      }
    }
  }

  &__image-highlight {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    pointer-events: none;

    & > h4 {
      width: 100%;
      background-color: #00000099;
      display: block;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: $whiteLight;
      padding: $xxl 0;
      @include applyFontSchema($displaySmall, $mulishFamily, $bold);
    }
  }
}
