.navbar {
  display: flex;
  width: 100%;
  height: 87px;
  position: fixed;
  padding: $l 0;
  z-index: 100;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  top: 0;

  &.scrolled {
    transition: all 0.4s ease-in-out;
    background-color: $brownFortyPerCent;
    backdrop-filter: blur(10px);
  }

  &.animate {
    animation: 1s fadeInToBottom ease-in-out;
  }

  & .icon-light,
  & .icon-dark {
    cursor: pointer;
  }

  &__layout {
    position: relative;
    z-index: 12;
    width: 100%;
    max-width: 80%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
  }

  &__content {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 11;
    transition: all 1s ease-in-out;
    background: $white;
    animation: fadeIn 1s ease-in-out;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
    overflow-y: auto;

    @media screen and (max-width: 1160px) {
      flex-direction: column;
    }

    @media screen and (max-width: 500px) {
      margin-top: -50px;
    }

    &.animation-back {
      animation: fadeOut 1s ease-in-out;
    }

    & > .signature {
      height: 220px;

      @media screen and (max-width: 1440px) {
        width: 710px;
        height: 170px;
      }

      @media screen and (max-width: 1160px) {
        width: 90%;
      }
      @media screen and (max-width: 500px) {
        display: none;
      }
    }
  }

  &__options {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin: 0 0 0 160px;
    animation: fadeInToLeft 1.5s ease-in-out;

    @media screen and (max-width: 1440px) {
      margin: 0 0 0 40px;
    }

    @media screen and (max-width: 1160px) {
      margin: 20px 0 0 0;
    }

    & > a {
      margin: 0 0 $sm 0;
      color: $brownLight;
      cursor: pointer;

      @include applyFontSchema($headlineMedium, $pfdFamily, $medium);

      @media screen and (max-width: 630px) {
        @include applyFontSchema($headlineSmall, $pfdFamily, $medium);
      }

      &:hover {
        color: $black;
      }

      &.active {
        color: $black;

        &::before {
          content: ' ';
          position: absolute;
          margin: 19px 0 0 -73px;
          width: 60px;
          height: 2px;
          background: $yellow;

          @media screen and (max-width: 1160px) {
            margin: 0;
          }
        }
      }
    }
  }

  &__controllers {
    display: flex;
    align-items: center;
    justify-content: center;

    & > .fi {
      margin: 0 $xl;

      @media screen and (max-width: 410px) {
        margin: 0 $sm;
      }
    }

    & > .burger {
      cursor: pointer;
      width: 29px;
      height: 29px;
    }
  }
}
