.property-features {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 250px;

  & > img {
    margin: 0 $s 0 0;
  }

  & > span {
    width: 100%;
    white-space: nowrap;
    color: $brown;
    @include applyFontSchema($titleMedium, $mulishFamily, $medium);
  }
}
