.all-properties {
  margin: 112px 0 48px 0;
  width: 100%;
  overflow: hidden;
  min-height: 100vh;

  @media screen and (max-width: 820px) {
    & .content-header {
      margin: 0 0 $l 0;
    }

    & .content-header__left-side > h3 {
      margin: 0;
    }
  }
}
