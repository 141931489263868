.service-item {
  column-gap: $xs;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  background-color: $whiteLight;
  padding: $xl 36px;
  border-radius: $m;
  z-index: 10;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;

  @media screen and (max-width: 520px) {
    display: none;
  }

  @media screen and (max-width: 420px) {
    max-width: 90%;
    margin: 0 auto;
  }

  &:nth-child(1),
  &:nth-child(2) {
    display: flex;
  }

  &:hover {
    transform: translateY(-$c);

    @media screen and (max-width: 970px) {
      transform: unset;
    }
  }

  &.animation {
    &:nth-child(1) {
      animation: fadeInToTopForServiceItem 1s ease-in-out;
    }

    &:nth-child(2) {
      animation: fadeInToTopForServiceItem 1.25s ease-in-out;
    }

    &:nth-child(3) {
      animation: fadeInToTopForServiceItem 1.5s ease-in-out;
    }

    &:nth-child(4) {
      animation: fadeInToTopForServiceItem 1.75s ease-in-out;
    }
  }

  & > article > p {
    display: flex;
    max-width: 234px;
    color: $brownLight;
    margin: 0 0 $m 0;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 8;
    line-clamp: 8;
    -webkit-box-orient: vertical;
    max-height: calc(8 * 20px);
    min-height: calc(8 * 20px);
    @include applyFontSchema($bodyMedium, $mulishFamily, $regular);
  }

  & > article > h2 {
    text-align: center;
    color: $black;
    margin: 0 0 $m 0;
    max-width: 234px;
    @include applyFontSchema($titleLarge, $pfdFamily, $bold);
  }
}
